import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import { RuleByNumberQuery } from "../../graphql-types";
import NotFoundPage from "../pages/404";
import styled from "styled-components";

const Container = styled.div`
  max-width: 800px;

  @media (min-width: 600px) {
    font-size: 48px;
    margin: 50px auto;
  }

  @media (max-width: 600px) {
    p {
      font-size: 24px;
    }
  }
`;

const RulePage: React.FC<{ data: RuleByNumberQuery }> = ({ data }) => {
  const markdownRemark = data.markdownRemark;

  if (!markdownRemark?.frontmatter || !markdownRemark?.html)
    return <NotFoundPage />;
  return (
    <Layout title={`Rule Number ${markdownRemark.frontmatter.number}`}>
      <Container>
        <h1>Rule Number {markdownRemark.frontmatter.number}</h1>
        <div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
      </Container>
    </Layout>
  );
};

export default RulePage;

export const pageQuery = graphql`
  query RuleByNumber($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        number
      }
    }
  }
`;
